<!-- @format -->

<template>
	<LayoutWide>
		<div>
			<div>
				<sub-navbar page="Admin" />
			</div>
			<div v-if="loading">...Loading</div>
			<div v-if="!loading">
				<div>
					<p>Beginning Date: {{ getDates().bd }}</p>
				</div>
				<div class="margin-bottom-20">
					<p>Ending Date: {{ getDates().ed }}</p>
				</div>
				<div>
					<b-button class="is-success margin-top-10" @click="refreshTable">
						Refresh
					</b-button>
				</div>
				<div class="margin-bottom-30 margin-top-30">
					<ve-table
						:rows="rowsList"
						:columns="columnsList"
						:onCellClick="onCellClick"
						:onRowDoubleClick="onRowDoubleClick"
						:sort-options="sortOptions"
					>
					</ve-table>
					<div v-if="!!isPopupVisible">
						<ModalReminderFrame :data="reminderData" :handler="refreshTable" />
					</div>
				</div>
			</div>
		</div>
	</LayoutWide>
</template>
<script>
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import { ref, reactive, onMounted } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import Store from '@/store'
import GetInvoicesQuery from '@/_srcv2/pages/admin/super-privileges/change-reminder-due-date/GetInvoicesQuery.graphql'
import dateFormat from 'dateformat'
import ModalReminderFrame from '@/_srcv2/pages/admin/super-privileges/change-reminder-due-date/ModalReminderFrame.vue'
import useReminderDueDateStore from '@/_srcv2/pages/admin/super-privileges/change-reminder-due-date/useChangeReminderDueDateStore.js'
export default {
	name: 'ChangeReminderDueDate',
	components: {
		SubNavbar,
		ModalReminderFrame,
	},
	setup() {
		const subtractYears = (numOfYears, date = new Date()) => {
			date.setFullYear(date.getFullYear() - numOfYears)
			return date
		}
		const getDates = (now = new Date()) => {
			const date = dateFormat(now, 'isoDate')
			const endingDate = date
			const tomorrow = now
			console.log('ending date: ', endingDate, 'tomorrow', tomorrow)
			tomorrow.setDate(tomorrow.getDate() + 1)
			const beginningDate = dateFormat(subtractYears(1, tomorrow), 'isoDate')
			console.log('beginningDate', beginningDate)
			return {
				ed: endingDate,
				bd: beginningDate,
			}
		}
		console.log(getDates())
		onMounted(() => {
			console.log('onMounted is fired')
			refreshTable()
		})
		const refreshTable = () => {
			refetch().then(() => {
				console.log('refreshTable')
			})
		}
		const sortOptions = {
			enabled: true,
			multipleColumns: true,
			initialSortBy: [
				{ field: 'reminderDueDate', type: 'asc' },
				{ field: 'invoiceNo', type: 'asc' },
			],
		}
		// * Apollo Query -------------------------------------------------------------------------
		const variablesForQuery = reactive({
			our_company: Store.getters.getUserCurrentCompany,
			bd: getDates().bd,
			ed: getDates().ed,
		})
		// *----------------------------------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { loading, refetch, onResult } = useQuery(
			GetInvoicesQuery,
			() => variablesForQuery,
			options,
		)
		// *----------------------------------------------------------------------------------------
		const columnsList = ref([
			{
				label: 'Invoice No',
				field: 'invoiceNo',
				width: '98px',
			},
			{
				label: 'Customer',
				field: 'customer',
				width: '355px',
			},
			{
				label: 'Invoice Date',
				field: 'invoiceDate',
				width: '108px',
			},
			{
				label: 'Invoice Due Date',
				field: 'invoiceDueDate',
				width: '108px',
			},
			{
				label: 'Reminder No',
				field: 'reminderNo',
				width: '80px',
			},
			{
				label: 'Reminder Date',
				field: 'reminderDate',
				width: '108px',
			},
			{
				label: 'Reminder Due Date',
				field: 'reminderDueDate',
				width: '118px',
			},
			{
				label: 'Payment Date',
				field: 'paymentDate',
				width: '108px',
			},
		])
		const rowsList = ref([])
		onResult((result) => {
			console.log('onResult', result?.data?.document_transactions)
			rowsList.value = result?.data?.document_transactions?.map((item) => {
				return {
					invoiceNo: item.invoice_number,
					customer: `${item.customer.customer_title} - ${item.customer.customer_nickname}`,
					invoiceDate: item.invoice_date,
					invoiceDueDate: item.invoice_due_date,
					reminderNo: item?.reminder_lines?.reminder_header?.reminder_id,
					reminderDate: item?.reminder_lines?.reminder_header?.reminder_date,
					reminderDueDate:
						item?.reminder_lines?.reminder_header?.reminder_due_date,
					paymentDate:
						item?.invoice_payments_aggregate?.aggregate?.max?.payment_date,
					ourCompany: item.our_company,
					customerId: item.customer.customer_id,
				}
			})
		})
		// ** ------------------------------------------------------------------------------------
		const reminderData = reactive({
			customer: '',
			invoiceNumber: '',
			invoiceDate: '',
			invoiceDueDate: '',
			reminderNumber: '',
			reminderDate: '',
			reminderDueDate: '',
		})
		// ---------------------------------------------------------------------------------------
		const openModal = (row) => {
			console.log(row)
			reminderData.customer = row.customer
			reminderData.invoiceNumber = row.invoiceNo
			reminderData.invoiceDate = row.invoiceDate
			reminderData.invoiceDueDate = row.invoiceDueDate
			reminderData.reminderNumber = row.reminderNo
			reminderData.reminderDate = row.reminderDate
			reminderData.reminderDueDate = row.reminderDueDate
			isPopupVisible.value = true
		}
		const onCellClick = (params) => {
			if (params.column.field === 'reminderDueDate') {
				openModal(params.row)
			} else if (params.column.field === 'reminderNo') {
				openModal(params.row)
			}
		}
		const onRowDoubleClick = (params) => {
			openModal(params.row)
		}

		// * ------------------------------------------------------------------------------
		const { isPopupVisible } = useReminderDueDateStore()
		return {
			refreshTable,
			sortOptions,
			loading,
			columnsList,
			rowsList,
			onCellClick,
			onRowDoubleClick,
			isPopupVisible,
			getDates,
			reminderData,
		}
	},
}
</script>
<style lang="css"></style>
