var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-wide',[_c('sub-nav-bar',{attrs:{"page":"Transaction"}}),_c('div',[_c('div',[_c('b-field',{staticClass:"margin-top-20",attrs:{"label":"Select payments date"}},[_c('b-datepicker',{attrs:{"max-date":_vm.today,"show-week-number":_vm.showWeekNumber,"locale":_vm.locale,"first-day-of-week":_vm.firstDayOfWeek,"placeholder":"Click to select...","icon":"calendar-today","trap-focus":""},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)],1),_c('div',[_c('b-button',{staticClass:"is-info margin-top-20 margin-right-10",attrs:{"type":"submit"},on:{"click":_vm.getAll}},[_vm._v("Get All")]),_c('b-button',{staticClass:"is-danger margin-top-20 margin-right-10",attrs:{"type":"submit"},on:{"click":_vm.getBank}},[_vm._v("Get Bank")]),_c('b-button',{staticClass:"is-success margin-top-20 margin-right-10",attrs:{"type":"submit"},on:{"click":_vm.getCash}},[_vm._v("Get Cash")]),_c('b-button',{staticClass:"is-warning margin-top-20",attrs:{"type":"submit"},on:{"click":_vm.getSetoff}},[_vm._v("Get Set Off")])],1),_c('div',[_c('div',[_c('div',[_c('div',[_c('div',{staticClass:"has-text-left",staticStyle:{"margin-top":"20px"}},[_c('span',{staticClass:"payment-type"},[_vm._v(" Total Payment Amount: ")]),_vm._v(" "+_vm._s(_vm.formatNumber(_vm.collectionTotals / 100, ' ', ',', 2, 'kr.'))+" "),_c('br'),_c('span',{staticClass:"payment-type"},[_vm._v(" Payment type: ")]),_c('span',{staticClass:"payment-type-value"},[_vm._v(" "+_vm._s(_vm.reportPaymentType)+" ")])]),_c('div',[_c('div',{staticClass:"margin-bottom-30 margin-top-10"},[_c('vue-good-table',{attrs:{"id":"dispatches","columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table striped bordered","theme":"black-rhino","max-height":"1100px","fixed-header":true,"pagination-options":{
										enabled: true,
										mode: 'records',
										perPage: 25,
										position: 'bottom',
										perPageDropdown: [5, 10, 15, 20, 25],
										dropdownAllowAll: false,
										setCurrentPage: 1,
										jumpFirstOrLast: true,
										firstLabel: 'First',
										lastLabel: 'Last',
										nextLabel: 'next',
										prevLabel: 'prev',
										rowsPerPageLabel: 'Rows per page',
										ofLabel: 'of',
										pageLabel: 'page', // for 'pages' mode
										allLabel: 'All',
									}},on:{"on-row-dblclick":_vm.onRowDoubleClick,"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'select')?_c('span',[_c('b-button',{staticClass:"is-small is-success"},[_vm._v("Cancel")])],1):_vm._e(),(props.column.field === 'preview')?_c('span',[_c('b-button',{staticClass:"is-small is-info"},[_vm._v("Preview")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }