/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

// todo ------------------------------------------------------------------------------------------------
async function getExchangeRates(excUnit) {
	console.log('excUnit', excUnit)
	const response = await fetch(
		`https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_xKXNX5PhvGE9yikxSSWikKkQLxZofwdn56x2HeyW&currencies=EUR%2CUSD%2CNOK%2CDKK%2CTRY%2CPLN&base_currency=SEK`,
	)
	const result = await response.json()
	const excRate = await result.data[excUnit]
	console.log('excRate', excRate)
	return excRate
}
// todo ------------------------------------------------------------------------------------------------
function getExchangeRatesCross(excUnit) {
	console.log('--- excUnit', excUnit)
	return fetch(
		`https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_xKXNX5PhvGE9yikxSSWikKkQLxZofwdn56x2HeyW&currencies=SEK&base_currency=${excUnit}`,
	)
		.then((response) => response.json())
		.then((result) => {
			// const firstKey = Object.keys(result.data)[0]
			return result.data['SEK']
			// {
			// 	date: firstKey,
			// 	SEK: result.data[firstKey]['SEK'],
			// }
		})
		.catch((error) => {
			console.error('Error fetching exchange rates:', error)
			throw error // rethrow error for potential further handling
		})
}
// ? ---------------------------------------------------------------------------------------------------
export default function useExchangeRate() {
	return {
		getExchangeRates,
		getExchangeRatesCross,
	}
}
// todo -------------------------------------------------------------------------------------------------
