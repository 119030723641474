<!-- @format -->

<template>
	<div>
		<div v-if="loading">Loading...</div>
		<div v-if="!loading">
			<div style="background-color: rgb(76, 92, 122)">
				<div
					style="
						display: flex;
						justify-content: start;
						font-weight: 900;
						padding: 20px;
					"
				>
					<div class="content margin-right-10" style="color: white">
						<b>Selection:</b>
					</div>
					<div style="color: white">
						<b-radio
							v-model="radioButton"
							type="is-warning"
							name="name"
							native-value="false"
						>
							Unsettled
						</b-radio>
						<b-radio
							v-model="radioButton"
							type="is-warning"
							name="name"
							native-value="true"
						>
							Settled
						</b-radio>
						<b-radio
							v-model="radioButton"
							name="name"
							type="is-warning"
							native-value="%"
						>
							All
						</b-radio>
					</div>
				</div>
				<div>
					<vue-good-table
						:columns="columns"
						:rows="rows"
						styleClass="vgt-table striped bordered"
						theme="black-rhino"
						:fixed-header="true"
						@on-cell-click="onCellClick"
						:search-options="{
							enabled: true,
						}"
						:sort-options="sortOptions"
						:pagination-options="{
							enabled: true,
							mode: 'records',
							perPage: 10,
							position: 'bottom',
							perPageDropdown: [10, 15, 20, 100],
							dropdownAllowAll: false,
							setCurrentPage: 1,
							jumpFirstOrLast: true,
							firstLabel: 'First',
							lastLabel: 'Last',
							nextLabel: 'next',
							prevLabel: 'prev',
							rowsPerPageLabel: 'Rows per page',
							ofLabel: 'of',
							pageLabel: 'page', // for 'pages' mode
							allLabel: 'All',
						}"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field === 'select'">
								<b-button class="is-small is-success">Select</b-button>
							</span>
							<span v-else-if="props.column.field === 'stockAmount'">
								<p class="text-bold text-align-right text-blue">
									{{ props.row.stockAmount }}
								</p>
							</span>
							<span v-else-if="props.column.field === 'outstandingBalance'">
								<p class="text-align-right">
									{{ props.formattedRow[props.column.field] }}
								</p>
							</span>
							<span v-else-if="props.column.field === 'outstandingCost'">
								<p class="text-bold text-green text-align-right">
									{{ (props.row.outstandingCost / 100).toFixed(2) }} kr.
								</p>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
				</div>
			</div>
		</div>
		<div style="display: flex; justify-content: space-between">
			<div>
				<b-button
					class="is-danger margin-top-30 margin-left-30"
					@click="setOutstandingValues"
					>Reset Outstanding Values</b-button
				>
			</div>
			<download-csv :data="rows">
				<div class="margin-top-30 width-200" style="margin-right: 15px">
					<b-button type="is-danger" outlined> Download the List </b-button>
				</div>
			</download-csv>
		</div>
		<div v-if="isModalVisible">
			<ModalStartupFrame :onClose="onCloseStartupFrame" />
		</div>
	</div>
</template>

<script>
// No need to change followings
import Store from '@/store'
// import Router from '@/router'
import {
	reactive,
	computed,
	onMounted,
	ref,
	watchEffect,
} from '@vue/composition-api'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import ModalStartupFrame from '@/_srcv2/views/pages/startup/layout/ModalStartupFrame.vue'
// Get the states
import useSelectedStockItemState from '@/_srcv2/views/_shared/states/useSelectedStockItemState'
import useStartupState from '@/_srcv2/views/pages/startup/layout/useStartupState.js'
// Change the the gql files for the query and mutation ******************************
import GetStockOutstandingValuesQuery from '@/_srcv2/views/pages/startup/layout/GetStockOutstandingValuesQuery.graphql'

import ResetOutstandingValuesMutation from '@/_srcv2/views/pages/startup/layout/ResetOutstandingValuesMutation.graphql'

export default {
	name: 'OutstandingValues',
	components: { ModalStartupFrame },
	setup() {
		const { isModalVisible, showOutstandingList } = useStartupState()
		const onCloseStartupFrame = () => {
			isModalVisible.value = false
		}
		const radioButton = ref('false')
		onMounted(() => {
			radioButton.value = 'false'
		})
		// ------------------------------------------------------------------------------------
		const ourCompany = computed(() => Store.getters.getUserCurrentCompany)
		// const searchText = computed(() => searchString.value)
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { result, loading, onResult, refetch } = useQuery(
			GetStockOutstandingValuesQuery,
			() => ({
				company_id: ourCompany.value,
				is_outstanding_set: radioButton.value,
			}),
			options,
		)
		console.log('my result', result)
		const searchQueryResults = useResult(result, null, (data) => data.stock)
		const {
			companyId,
			stockId,
			stockName,
			stockUnit,
			// stockAmount,
			outstandingBalance,
			outstandingCost,
		} = useSelectedStockItemState()
		// const storeSelectedStockItemsDetails = (item) => {
		// 	console.log('item', item)
		// 	companyId.value = item.company_id
		// 	stockId.value = item.stock_id
		// 	stockName.value = item.stock_name
		// 	stockUnit.value = item.stock_unit
		// 	stockAmount.value = item.stock_amount
		// 	outstandingBalance.value = item.outstanding_balance
		// 	outstandingCost.value = item.outstanding_cost
		// }
		// const routeTo = (component) => {
		// 	switch (component) {
		// 		case 'SetOutstandingValues':
		// 			Router.push({ name: 'SetOutstandingValues' })
		// 			break
		// 		default:
		// 			return
		// 	}
		// }
		// ** -----------------------------------------------------------------------------------
		const rows = ref([])
		const columns = [
			{
				label: 'Select',
				field: 'select',
				sortable: false,
			},
			{
				field: 'itemInfo',
				label: 'Item Info',
			},
			// {
			// 	field: 'stockAmount',
			// 	label: 'Stock Amount',
			// },
			{
				field: 'outstandingBalance',
				label: 'Outstanding Balance',
			},
			{
				field: 'outstandingCost',
				label: 'Outstanding Cost',
			},
		]
		onResult((result) => {
			console.log('onResult data', result.data.stock)
			rows.value = result.data.stock.map((item) => {
				return {
					itemInfo:
						item.stock_id + ' - ' + item.stock_name + ' - ' + item.stock_unit,
					// stockAmount: item.stock_amount,
					outstandingBalance: item.outstanding_balance,
					// outstandingCost: Math.floor(item.outstanding_cost / 100),
					outstandingCost: item.outstanding_cost,
					companyId: item.company_id,
					stockId: item.stock_id,
					stockName: item.stock_name,
					stockUnit: item.stock_unit,
				}
			})
		})
		watchEffect(() => {
			console.log('-------- rows', rows.value)
		})
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'itemInfo', type: 'asc' },
		}
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				console.log('params.row', params.row)
				let item = params.row
				companyId.value = item.companyId
				stockId.value = item.stockId
				stockName.value = item.stockName
				stockUnit.value = item.stockUnit
				// stockAmount.value = item.stockAmount
				outstandingBalance.value = item.outstandingBalance
				outstandingCost.value = item.outstandingCost
				isModalVisible.value = true
			}
		}
		const onRowDoubleClick = () => 42
		// ** -----------------------------------------------------------------------------------
		// * set password
		const mutationVariable = computed(() => {
			return {
				company_id: Store.getters.getUserCurrentCompany,
			}
		})
		const {
			mutate: mutateSet,
			onDone: onDoneSet,
			onError: onErrorSet,
		} = useMutation(ResetOutstandingValuesMutation, () => ({
			variables: mutationVariable.value,
		}))
		onDoneSet(() => {
			refetch()
			alert('Outstanding values are set.')
		})
		onErrorSet((err) => console.log('Error: ', err))

		const setOutstandingValues = () => {
			if (
				window.confirm(
					'Are you sure you want to reset all outstanding values to 0? All previous values will be lost.',
				)
			) {
				if (
					window.confirm(
						`Are you sure you want to reset all outstanding values to 0 for the company ${ourCompany}? All previous values will be lost.`,
					)
				) {
					mutateSet()
				} else {
					return
				}
			} else {
				return
			}
		}
		// ** -----------------------------------------------------------------------------------
		// todo ------------------------------------
		return {
			result,
			searchQueryResults,
			radioButton,
			// ** --------------------
			loading,
			rows,
			columns,
			sortOptions,
			onCellClick,
			onRowDoubleClick,
			isModalVisible,
			onCloseStartupFrame,
			showOutstandingList,
			setOutstandingValues,
		}
	},
}
</script>

<style scoped>
.list-item {
	margin-top: 10px;
}
.margin-right-15 {
	margin-right: 50px;
}
.second-line {
	margin-left: 65px;
	margin-top: -20px;
}
.small-btn {
	margin: 0 11px 0 0;
}
.alarm {
	color: red;
}
.store {
	color: green;
}
.rebeccapurple {
	color: rebeccapurple;
}
.blue {
	color: blue;
}
</style>
