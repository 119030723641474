import { render, staticRenderFns } from "./GetDataCollectPayment.vue?vue&type=template&id=5fa0863b&scoped=true&"
import script from "./GetDataCollectPayment.vue?vue&type=script&lang=js&"
export * from "./GetDataCollectPayment.vue?vue&type=script&lang=js&"
import style0 from "./GetDataCollectPayment.vue?vue&type=style&index=0&id=5fa0863b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fa0863b",
  null
  
)

export default component.exports