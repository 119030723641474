<!-- @format -->

<template>
	<div>
		<div>
			<div style="background-color: rgb(76, 92, 122)">
				<div class="table-header">
					{{ tableTitleByType }}
					<b-button
						v-if="isCustomerActive"
						type="is-danger"
						@click="setInactive"
						>Show Inactive Customers</b-button
					>
					<b-button
						v-if="!isCustomerActive"
						type="is-success"
						@click="setActive"
						>Show Active Customers</b-button
					>
				</div>
			</div>
			<vue-good-table
				:columns="columns"
				:rows="rows"
				styleClass="vgt-table striped bordered"
				theme="black-rhino"
				max-height="700px"
				:fixed-header="true"
				@on-cell-click="onCellClick"
				@on-row-dblclick="onRowDoubleClick"
				:search-options="{
					enabled: true,
				}"
				:pagination-options="{
					enabled: true,
					mode: 'records',
					perPage: 10,
					position: 'bottom',
					perPageDropdown: [5, 10, 15, 20],
					dropdownAllowAll: true,
					setCurrentPage: 1,
					jumpFirstOrLast: true,
					firstLabel: 'First',
					lastLabel: 'Last',
					nextLabel: 'next',
					prevLabel: 'prev',
					rowsPerPageLabel: 'Rows per page',
					ofLabel: 'of',
					pageLabel: 'page', // for 'pages' mode
					allLabel: 'All',
				}"
			>
				<template slot="table-row" slot-scope="props">
					<span v-if="props.column.field === 'select'">
						<b-button class="is-small is-success">Add Address</b-button>
					</span>
					<span v-else-if="props.column.field === 'specialPrice'">
						<b-button class="is-small is-info">Add Prices</b-button>
					</span>
					<span v-else>
						{{ props.formattedRow[props.column.field] }}
					</span>
				</template>
			</vue-good-table>
		</div>
		<div>
			<div id="customer" v-if="props.customerType === 'customer'">
				<div class="max-width-150">
					<ModalEditCustomer v-if="isModalVisible" @close="closeModal" />
				</div>
				<div class="max-width-150">
					<ModalAddCustomer
						v-if="isModalAddCustomerVisible"
						@close="closeAddCustomerModal"
					/>
				</div>
			</div>
			<div
				id="potential-customer"
				v-if="props.customerType === 'potentialCustomer'"
			>
				<div class="max-width-150">
					<ModalEditPotentialCustomer
						v-if="isModalPotentialVisible"
						@close="closeModalPotential"
					/>
				</div>
				<div class="max-width-150">
					<ModalAddPotentialCustomer
						v-if="isModalAddPotentialCustomerVisible"
						@close="closeAddPotentialCustomerModal"
					/>
				</div>
			</div>
			<div class="max-width-150">
				<ModalAddCustomersAddress
					v-if="isModalAddCustomersAddressVisible"
					@close="closeAddCustomersAddressModal"
				/>
			</div>
			<div
				style="
					background-color: rgb(76, 92, 122);
					padding-bottom: 30px;
					display: flex;
					justify-content: space-between;
				"
			>
				<div v-if="props.customerType === 'customer'">
					<b-button
						class="margin-top-30 margin-left-15 width-250"
						style="background-color: rgb(182, 90, 92); color: white"
						@click="addNewCustomer"
					>
						Add New Customer
					</b-button>
				</div>
				<div v-if="props.customerType === 'potentialCustomer'">
					<b-button
						class="margin-top-30 margin-left-15 width-250"
						style="background-color: rgb(61, 196, 134); color: white"
						@click="addPotentialCustomer"
					>
						Add potential customer
					</b-button>
				</div>
				<div>
					<download-csv :data="rows">
						<div class="margin-top-30 width-250" style="margin-right: 15px">
							<b-button type="is-danger" outlined>
								Download the Customers List
							</b-button>
						</div>
					</download-csv>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { onMounted, reactive, ref, computed } from '@vue/composition-api'
import { useQuery, useResult } from '@vue/apollo-composable'
import GetCustomerListQuery from '@/_srcv2/views/pages/admin-reports/customer-list/GetCustomerListQuery.gql'
import Store from '@/store'
import ModalEditCustomer from './ModalEditCustomer.vue'
import ModalAddCustomer from './ModalAddCustomer.vue'
import ModalEditPotentialCustomer from '@/_srcv2/pages/business-development/potential-customers/components/ModalEditPotentialCustomer.vue'
import ModalAddPotentialCustomer from '@/_srcv2/pages/business-development/potential-customers/components/ModalAddPotentialCustomer.vue'
import ModalAddCustomersAddress from './ModalAddCustomersAddress'
import useCustomerDetailsForm from '@/_srcv2/views/pages/admin-reports/customer-list/useCustomerDetailsForm.js'

export default {
	name: 'CustomerDetailsForm',
	components: {
		ModalEditCustomer,
		ModalAddCustomer,
		ModalEditPotentialCustomer,
		ModalAddPotentialCustomer,
		ModalAddCustomersAddress,
	},
	props: {
		customerType: {
			type: String,
			default: 'customer',
		},
	},
	setup(props) {
		const tableTitleByType = computed(() => {
			if (props.customerType === 'customer') {
				return 'Customers List (Filtered)'
			} else if (props.customerType === 'potentialCustomer') {
				return 'Potential Customers List (Filtered)'
			}
		})
		const {
			isModalVisible,
			isModalAddCustomerVisible,
			isModalPotentialVisible,
			isModalAddPotentialCustomerVisible,
			isModalAddCustomersAddressVisible,
			ourCompany,
			id,
			nickname,
			title,
			orgNum,
			discount,
			email,
			isActive,
			creditLimit,
			isRisky,
			reference,
			customerTerm,
			customerInvoiceRule,
			sendInvoiceBy,
			customerPriceClass,
			customerType,
			// modelCompanies,
		} = useCustomerDetailsForm()
		// const closeModal = () => (isModalVisible.value = false)
		const closeModal = () => {
			if (props.customerType === 'customer') {
				isModalVisible.value = false
			} else if (props.customerType === 'potentialCustomer') {
				isModalPotentialVisible.value = false
			} else return
		}
		const showModal = () => {
			if (props.customerType === 'customer') {
				isModalVisible.value = true
			} else if (props.customerType === 'potentialCustomer') {
				isModalPotentialVisible.value = true
			} else return
		}
		// todo ----------------------------------------------
		const closeModalPotential = () => (isModalPotentialVisible.value = false)
		const showModalPotential = () => (isModalPotentialVisible.value = true)
		// todo ----------------------------------------------
		const closeAddCustomerModal = () =>
			(isModalAddCustomerVisible.value = false)
		const showAddCustomerModal = () => (isModalAddCustomerVisible.value = true)
		// todo ----------------------------------------------
		const closeAddPotentialCustomerModal = () =>
			(isModalAddPotentialCustomerVisible.value = false)
		const showAddPotentialCustomerModal = () =>
			(isModalAddPotentialCustomerVisible.value = true)
		// todo ----------------------------------------------
		const closeAddCustomersAddressModal = () =>
			(isModalAddCustomersAddressVisible.value = false)
		const showAddCustomersAddressModal = () =>
			(isModalAddCustomersAddressVisible.value = true)
		// todo ----------------------------------------------
		const setInit = () => {
			customerType.value = props.customerType
			isModalVisible.value = false
			isModalAddCustomerVisible.value = false
			isModalPotentialVisible.value = false
			isModalAddPotentialCustomerVisible.value = false
			isModalAddCustomersAddressVisible.value = false
		}
		const isCustomerActive = ref(true)
		const setInactive = () => {
			isCustomerActive.value = false
			refetch()
		}
		const setActive = () => {
			isCustomerActive.value = true
			getCustomers()
		}

		onMounted(() => {
			isCustomerActive.value = true
			setInit()
			refetch()
		})
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch, result, onResult } = useQuery(
			GetCustomerListQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				customer_type: props.customerType,
				customer_is_active: computed(() => isCustomerActive.value).value,
			}),
			options,
		)
		onResult((data) => {
			console.log('data', data.data.customers)
			fetchCustomers(data.data.customers)
			alert('Inactive customers are shown')
		})
		const allActiveCustomers = useResult(result, null, (data) => data.customers)
		const getCustomers = () => {
			refetch()
				.then(() => fetchCustomers(allActiveCustomers))
				.then((customers) => objectToCSV(customers))
				.then((data) => {
					csvFile.value = data
				})
		}
		const csvFile = ref({})
		const fetchCustomers = (payload) => {
			// console.log('data.customers', data)
			return new Promise((resolve, reject) => {
				const customers = payload.map((item) => {
					return {
						companyId: item.company_id,
						customerId: item.customer_id,
						customerTitle: item.customer_title,
						customerNickname: item.customer_nickname || '',
						customer: `${item.customer_title} - ${item.customer_nickname}`,
						customerOrgNumber: item.customer_org_num,
						customerEmail: item.customer_email || '',
						customerInvoiceRule: item.customer_invoice_rule,
						customerTermF: `${item.customer_term} days`,
						customerTerm: item.customer_term,
						customerPriceClassF: `Class ${item.customer_price_class}`,
						customerPriceClass: item.customer_price_class,
						customerReference: item.customer_reference || '',
						customerDiscount: item.customer_discount_percent,
						isActive: item.customer_is_active,
						isRisky: item.customer_is_risky,
						creditLimit: item.customer_credit_limit,
						sendInvoiceBy: item.send_invoice_by,
					}
				})
				if (payload !== null || undefined) {
					rows.value = customers
					resolve(customers)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const objectToCSV = (data) => {
			const csvRows = []

			// get headers
			const headers = Object.keys(data[0])
			csvRows.push(headers.join(','))

			// loop over values
			for (const row of data) {
				const values = headers.map((header) => {
					const escaped = ('' + row[header]).replace(/"/g, '\\"')
					return `"${escaped}"`
				})
				csvRows.push(values.join(','))
			}

			// join and return all lines
			return csvRows.join('\n')
		}

		const download = (data) => {
			const anchor = document.createElement('a')
			anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data)
			anchor.target = '_blank'
			anchor.download = 'nameYourFileHere.csv'
			anchor.click()
		}
		const rows = ref([])
		const columns = [
			{
				label: 'Customer Number',
				field: 'customerId',
				width: '135px',
			},
			{
				label: 'Customer',
				field: 'customer',
			},
			{
				label: 'Email',
				field: 'customerEmail',
				width: '115px',
			},
			{
				label: 'Invoice Rule',
				field: 'customerInvoiceRule',
				width: '85px',
			},
			{
				label: 'Term',
				field: 'customerTermF',
				width: '85px',
			},
			{
				label: 'Price Class',
				field: 'customerPriceClassF',
				width: '85px',
			},
			{
				label: 'Special Price',
				field: 'specialPrice',
				width: '105px',
			},
			{
				label: 'Add Address',
				field: 'select',
				width: '105px',
			},
		]
		const getModalFormFieldValues = (row) => {
			return new Promise((resolve, reject) => {
				if (row !== null || undefined) {
					ourCompany.value = row.companyId
					id.value = row.customerId
					nickname.value = row.customerNickname
					title.value = row.customerTitle
					orgNum.value = row.customerOrgNumber
					discount.value = row.customerDiscount
					email.value = row.customerEmail
					isActive.value = row.isActive
					creditLimit.value = row.creditLimit
					isRisky.value = row.isRisky
					reference.value = row.customerReference
					customerTerm.value = row.customerTerm
					customerInvoiceRule.value = row.customerInvoiceRule
					sendInvoiceBy.value = row.sendInvoiceBy
					customerPriceClass.value = row.customerPriceClass
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const onRowDoubleClick = (params) => {
			getModalFormFieldValues(params.row).then(() => showModal())
		}
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				Store.dispatch('setAddressListCustomer', params.row.customerId)
				Store.dispatch(
					'setAddressListOurCompany',
					Store.getters.getUserCurrentCompany,
				)
				Store.dispatch(
					'setAddressListCustomerInfo',
					params.row.customerId + ' - ' + params.row.customer,
				)
				showAddCustomersAddressModal()
			} else if (params.column.field === 'specialPrice') {
				alert('This function is not activated yet! Coming soon!')
			}
		}
		const addNewCustomer = () => {
			// customerType.value = 'customer'
			showAddCustomerModal()
		}
		const addPotentialCustomer = () => {
			// customerType.value = 'potentialCustomer'
			title.value = ''
			orgNum.value = ''
			customerTerm.value = 0
			customerInvoiceRule.value = ''
			sendInvoiceBy.value = ''
			customerPriceClass.value = 'd'
			discount.value = 0
			email.value = ''
			creditLimit.value = 0
			isActive.value = true
			isRisky.value = false
			showAddPotentialCustomerModal()
		}
		return {
			tableTitleByType,
			isModalVisible,
			showModal,
			closeModal,
			showModalPotential,
			closeModalPotential,
			isModalAddCustomerVisible,
			showAddCustomerModal,
			closeAddCustomerModal,
			closeAddPotentialCustomerModal,
			isModalAddCustomersAddressVisible,
			showAddCustomersAddressModal,
			closeAddCustomersAddressModal,
			onRowDoubleClick,
			onCellClick,
			addNewCustomer,
			addPotentialCustomer,
			download,
			// allActiveCustomers,
			objectToCSV,
			rows,
			columns,
			props,
			isModalPotentialVisible,
			isModalAddPotentialCustomerVisible,
			setInactive,
			setActive,
			isCustomerActive,
		}
	},
}
</script>

<style scoped></style>
