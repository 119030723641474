<template>
	<div>
		<b-field>
			<b-input
				type="textarea"
				:minlength="$props.minLength"
				:maxlength="$props.maxLength"
				:placeholder="$props.placeholder"
				has-counter
			>
			</b-input>
		</b-field>
	</div>
</template>

<script>
export default {
	name: 'TextEditor',
	props: {
		placeholder: {
			type: String,
			default: 'Please enter your text',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		maxLength: {
			type: Number,
			default: 1000,
		},
		minLength: {
			type: Number,
			default: 0,
		},
	},
}
</script>

<style lang="scss" scoped></style>
