var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticStyle:{"font-weight":"900","color":"red","margin-top":"60px"}},[_vm._v(" ...Loading ")]):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"table-color",staticStyle:{"margin-top":"-20px"}},[_c('div',{staticStyle:{"font-weight":"900"}},[_vm._v(" All unpaid invoices"),_c('br'),_c('span',{staticClass:"text-white"},[_vm._v("Between the dates:")]),_vm._v("   "+_vm._s(_vm.beginningDate)+"   and   "+_vm._s(_vm.endingDate)+" "),_c('div',[_c('b-button',{staticClass:"width-250",staticStyle:{"background-color":"rgb(52, 136, 206)","color":"white","margin-left":"0px","margin-top":"30px","margin-bottom":"30px"},on:{"click":_vm.toggleDays}},[_vm._v(" "+_vm._s(_vm.buttonTag)+" ")]),_c('b-button',{staticClass:"width-250",staticStyle:{"background-color":"rgb(61, 196, 134)","color":"white","margin-left":"30px","margin-top":"30px","margin-bottom":"30px"},on:{"click":_vm.setInitialValues}},[_vm._v(" Refresh the table ")])],1)]),_c('div',{attrs:{"vif":"refreshTableValues"}},[_c('vue-good-table',{attrs:{"id":"dispatches","columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table striped bordered","theme":"black-rhino","max-height":"700px","fixed-header":true,"search-options":{
					enabled: true,
				},"sort-options":_vm.sortOptions,"pagination-options":{
					enabled: true,
					mode: 'records',
					perPage: 10,
					position: 'bottom',
					perPageDropdown: [5, 10, 15, 20],
					dropdownAllowAll: true,
					setCurrentPage: 1,
					jumpFirstOrLast: true,
					firstLabel: 'First',
					lastLabel: 'Last',
					nextLabel: 'next',
					prevLabel: 'prev',
					rowsPerPageLabel: 'Rows per page',
					ofLabel: 'of',
					pageLabel: 'page',
					allLabel: 'All',
				}},on:{"on-row-dblclick":_vm.onRowDoubleClick,"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'select')?_c('span',[_c('b-button',{staticClass:"is-small is-success",attrs:{"disabled":_vm.isSelectDisabled}},[_vm._v(" Select ")])],1):(props.column.field === 'invoiceTotalExchange')?_c('span',[_c('p',{staticClass:"text-bold text-purple text-align-right"},[_vm._v(" "+_vm._s(_vm.numeral(props.row.invoiceTotalExchange / 100).format('0,0.00'))+" ")])]):(props.column.field === 'paymentExchange')?_c('span',[_c('p',{staticClass:"text-bold text-green text-align-right"},[_vm._v(" "+_vm._s(_vm.numeral(props.row.paymentExchange / 100).format('0,0.00'))+" ")])]):(props.column.field === 'balanceExchange')?_c('span',[_c('p',{staticClass:"text-bold text-red text-align-right"},[_vm._v(" "+_vm._s(_vm.numeral(props.row.balanceExchange / 100).format('0,0.00'))+" ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,2871611815)})],1),_c('download-csv',{attrs:{"data":_vm.rows}},[_c('div',{staticClass:"margin-bottom-30 margin-top-30"},[_c('b-button',{staticClass:"width-250",staticStyle:{"background-color":"rgb(61, 196, 134)","color":"white"}},[_vm._v(" Download the List ")])],1)])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }