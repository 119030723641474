<!-- @format -->

<template>
	<div>
		<div v-if="isPopupVisible">
			<VPopup :closePopup="onClosePopup">
				<template #header>
					<div>
						{{ formName }}
					</div>
				</template>
				<template #body>
					<div v-if="popupType === 'edit'">
						<EditStockItem />
					</div>
					<div v-if="popupType === 'add'">
						<AddStockItem />
					</div>
				</template>
				<template #footer>
					<div>
						{{ formName }}
					</div>
				</template>
			</VPopup>
		</div>
		<div>
			<div v-if="loading">Loading.....</div>
		</div>
		<div v-if="!loading">
			<b-field>
				<b-switch v-model="listType">
					{{
						listType ? 'Pizzerias price list' : 'Hotel Restaurants price list'
					}}
				</b-switch>
			</b-field>
			<ve-table
				:columns="columns"
				:rows="rows"
				:onCellClick="onCellClick"
				:onRowDoubleClick="onRowDoubleClick"
			>
				<template slot="table-row" slot-scope="props">
					<span v-if="props.column.field === 'select'">
						<b-button class="is-small is-success">Select</b-button>
					</span>
					<span v-else>
						{{ props.formattedRow[props.column.field] }}
					</span>
				</template>
			</ve-table>
			<b-button
				class="is-success margin-top-30 margin-bottom-30"
				@click="showAddForm"
			>
				Add new stock item
			</b-button>
		</div>
	</div>
</template>

<script>
import { useQuery } from '@vue/apollo-composable'
import { onMounted, ref, watchEffect } from '@vue/composition-api'
import Store from '@/store'
import GetPriceListQuery from '@/_srcv2/graphql/stock/queries/getPriceListQuery.graphql'
import useStockItemStore from '@/_srcv2/pages/admin/edit-stock-prices/sub-components/useStockItemStore.js'
import VPopup from '@/_srcv2/pages/admin/edit-stock-prices/sub-components/VPopup.vue'
import EditStockItem from '@/_srcv2/pages/stock-ours/edit-view-stock-item/EditStockItem.vue'
import AddStockItem from '@/_srcv2/pages/stock-ours/add-stock-item/AddStockItem.vue'
import stockItemStore from '@/_srcv2/pages/stock-ours/store-stock/useStockItemState.js'
// ! -------------------------------------------------------------------------------------
import GetStockItemQuery from '@/_srcv2/pages/stock-ours/graphql/GetStockItemQuery.graphql'
import useReportNumber from '@/_srcv2/views/_shared/_composables/format-report-numbers/useReportNumber.js'

export default {
	name: 'SearchStockItems',
	components: {
		VPopup,
		EditStockItem,
		AddStockItem,
	},
	setup() {
		const formName = ref('')
		const setFormName = (name) => {
			formName.value = name
		}
		// ! ------------------------------------------------------
		const { refetch, loading } = useQuery(
			GetPriceListQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
			}),
			() => {
				return {
					fetchPolicy: 'no-cache',
				}
			},
		)
		// -----------------------------------------------------------------
		const { columnsPizza, columnsHR, isPopupVisible } = useStockItemStore()
		const columns = ref([])
		const rows = ref([])
		const listType = ref(true)
		// -----------------------------------------------------------------
		onMounted(() => {
			if (listType.value) {
				columns.value = columnsPizza.value
			}
			if (!listType.value) {
				columns.value = columnsHR.value
			}
			refetch().then((result) => {
				rows.value = result.data.stock
			})
		})
		// ! --------------------------------------------------------------
		watchEffect(() => {
			if (listType.value) {
				columns.value = columnsPizza.value
			}
			if (!listType.value) {
				columns.value = columnsHR.value
			}
		})
		// ! ---------------------------------------------------------------
		// const queryEnabled = ref(false)
		const selectedStockItemId = ref('')
		const { refetch: refetchStockItem, onResult: onResultStockItem } = useQuery(
			GetStockItemQuery,
			() => ({
				stock_id: selectedStockItemId.value,
				company_id: Store.getters.getUserCurrentCompany,
			}),
			() => ({
				fetchPolicy: 'no-cache',
				// enabled: queryEnabled.value,
			}),
		)
		// onResult4().then((result) => console.log('------------ result', result))
		// ! -----------------------------------------------------------
		const showEditForm = () => {
			isPopupVisible.value = true
			popupType.value = 'edit'
		}
		// ! -----------------------------------------------------------
		const showAddForm = () => {
			isPopupVisible.value = true
			popupType.value = 'add'
			setFormName('Add New Stock Item')
		}
		// * ------------------------------------------------------------
		const popupType = ref('')
		// const isPopupVisible = ref(false)
		const onClosePopup = () => {
			isPopupVisible.value = false
			popupType.value = ''
		}
		const isComponentModalActive = ref(true)
		// * ------------------------------------------------------------
		const { intToFloatCleave } = useReportNumber()
		const { modelStock } = stockItemStore()
		// *** -----------------------------------------------------------
		const setVariablesQuery = (item) => {
			console.log('55555', item)
			if (item !== null || undefined) {
				selectedStockItemId.value = item.stock_id
				isConfirmationShown.value = true
				showConfirmation()
			}
		}
		const setFormFieldsValues = (result) => {
			console.log('------- stockItem', result)
			return new Promise((resolve, reject) => {
				if (result !== null || undefined) {
					const item = result.data.stock[0]
					console.log('*** item', item)
					const campaignPrice = intToFloatCleave(item.campaign_price)
					modelStock.stockImageLink = item.stock_image_link
					modelStock.stockId = item.stock_id
					modelStock.stockName = item.stock_name
					modelStock.stockUnit = item.stock_unit
					modelStock.stockPrice = intToFloatCleave(item.stock_price)
					modelStock.stockPriceA = intToFloatCleave(item.stock_price_a)
					modelStock.stockPriceB = intToFloatCleave(item.stock_price_b)
					modelStock.stockPriceC = intToFloatCleave(item.stock_price_c)
					modelStock.stockPriceD = intToFloatCleave(item.stock_price_d)
					modelStock.stockPriceS = intToFloatCleave(item.stock_price_s)
					modelStock.stockPriceZ = intToFloatCleave(item.stock_price_z)
					modelStock.stockPriceHRA = intToFloatCleave(item.stock_price_hra)
					modelStock.stockPriceHRB = intToFloatCleave(item.stock_price_hrb)
					modelStock.stockPriceHRC = intToFloatCleave(item.stock_price_hrc)
					modelStock.stockPriceHRD = intToFloatCleave(item.stock_price_hrd)
					modelStock.stockMoms = item.stock_moms
					modelStock.stockGroup = item.stock_group
					modelStock.stockAlarm = item.stock_alarm
					modelStock.stockShelf = item.stock_shelf
					modelStock.companyId = item.company_id
					modelStock.stockIsActive = item.stock_is_active
					modelStock.campaignPrice =
						// eslint-disable-next-line use-isnan
						campaignPrice == NaN ||
						campaignPrice === undefined ||
						campaignPrice === null ||
						campaignPrice === ''
							? 0
							: campaignPrice
					modelStock.isCampaignActive = false
					resolve('ok')
				} else {
					const reason = new Error('Data could not be set')
					reject(reason)
				}
			})
		}
		// ! -------------------------------------------------------------------------
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				console.log('params.row', params.row)
				setVariablesQuery(params.row)
			}
		}
		const onRowDoubleClick = (params) => {
			setVariablesQuery(params.row)
		}
		// ! ----------------------------------------------------------------------
		onResultStockItem((result) => {
			console.log('*** onResult is fired', result.data)
			if (isConfirmationShown.value === true) {
				console.log(
					'onResultStockItem isConfirmationShown',
					isConfirmationShown.value,
				)
				setFormFieldsValues(result).then(() => {
					setFormName('Edit Stock Item')
					showEditForm()
				})
			} else {
				console.log('else is fired')
			}
		})
		// ! -----------------------------------------------------------
		const isConfirmationShown = ref(false)
		const showConfirmation = () => {
			if (
				confirm(
					'Are you sure to edit the stock item \n To cancel edit press "ok" \n To return back press "cancel"',
				)
			) {
				refetchStockItem()
			} else {
				isConfirmationShown.value = false
			}
		}
		return {
			loading,
			columns,
			rows,
			onRowDoubleClick,
			onCellClick,
			listType,
			isPopupVisible,
			onClosePopup,
			popupType,
			showAddForm,
			isComponentModalActive,
			formName,
		}
	},
}
</script>

<style scoped>
.toppest {
	z-index: 1000;
}
</style>
