/** @format */

import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)
import { ref, computed, watch, isRef } from '@vue/composition-api'

import useTransactionsNewState from '@/_srcv2/pages/transactions-new/store/useTransactionsNewState.js'
const { modelTransaction, selectedInvoiceState } = useTransactionsNewState()
const setFormValues = (
	paymentType,
	transactionType,
	paymentCreditAccount,
	paymentDebitAccount,
	reportType,
) => {
	// todo Set types
	modelTransaction.paymentType = paymentType
	modelTransaction.transactionType = transactionType
	// todo Set Form fields values
	modelTransaction.paymentCreditAccount = paymentCreditAccount
	modelTransaction.paymentDebitAccount = paymentDebitAccount
	// todo ---------------------------------------------------------------
	modelTransaction.invoiceType = selectedInvoiceState.invoiceType
	modelTransaction.reportType = reportType
}
const getVariables = (variable) => {
	switch (variable) {
		// ********** Collect Payment ******************************
		case 'collect bank':
			setFormValues(
				'collect bank',
				'Customer pays our invoice via bank',
				'1510 Kundfordringar',
				'1930 Bankkonto',
				'IB',
			)
			break
		case 'collect cash':
			setFormValues(
				'collect cash',
				'Customer pays our invoice in cash',
				'1510 Kundfordringar',
				'1910 Kassa',
				'IB',
			)
			break
		case 'collect bank in advance':
			setFormValues(
				'collect bank in advance',
				'Customer pays our invoice via bank in advance',
				'1511 Kundfordringar',
				'1930 Bankkonto',
				'IB',
			)
			modelTransaction.paymentCreditExchange = ''
			break
		case 'collect cash in advance':
			setFormValues(
				'collect cash in advance',
				'Customer pays our invoice in cash in advance',
				'1511 Kundfordringar',
				'1930 Bankkonto',
				'IB',
			)
			modelTransaction.paymentCreditExchange = ''
			break
		case 'collect bank reminder fee':
			setFormValues(
				'collect bank reminder fee',
				'Customer pays our reminder fee via bank',
				'3591 Förseningsavgifter, ej moms',
				'1930 Bankkonto',
				'IB',
			)
			modelTransaction.paymentCreditExchange = ''
			break
		case 'collect cash reminder fee':
			setFormValues(
				'collect cash reminder fee',
				'Customer pays our reminder fee in cash',
				'3591 Förseningsavgifter, ej moms',
				'1930 Bankkonto',
				'IB',
			)
			modelTransaction.paymentCreditExchange = ''
			break
		case 'get payback via collect bank':
			setFormValues(
				'get payback via collect bank',
				'Supplier is making a refund to us via bank',
				'2440 Leverantörsskulder',
				'1930 Bankkonto',
				'IB',
			)
			break
		case 'get payback collect cash':
			setFormValues(
				'get payback collect cash',
				'Supplier is making a cash refund to us',
				'2440 Leverantörsskulder',
				'1910 Kassa',
				'IB',
			)
			break
		// ********** set off records ******************************
		case 'set off credit invoice':
			setFormValues(
				'set off credit invoice',
				'We credit the customer against the "Credit Invoice"',
				'1510 Kundfordringar',
				'1511 Kundfordringar',
				'SO',
			)
			break
		case 'set off advanced payment':
			setFormValues(
				'set off advanced payment',
				'We credit the customer against the "Advanced Payment"',
				'1510 Kundfordringar',
				'1511 Kundfordringar',
				'SO',
			)
			break
		default:
			window.alert('Something went wrong when getting accounts!')
	}
}

// ! --------------------------------------------------------------------------------------------------------------------
// **********************************************************************************************************************
function useFormatNumber(
	thousandSeparator = ' ',
	decimalSeparator = ',',
	digitLimit = 2,
	maxValue = null, // Optional maxValue, defaults to null
) {
	const inputValue = ref('') // Formatted value

	// Method to format the number with custom thousand and decimal separators
	const formatNumber = (value) => {
		const parts = value.split(decimalSeparator)

		// Format the integer part with the chosen thousand separator
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)

		// If there's a decimal part, limit it to the specified number of digits
		if (parts[1]) {
			parts[1] = parts[1].slice(0, digitLimit) // Limit decimal digits
		}

		return parts.join(decimalSeparator)
	}

	// Method to strip the formatting (remove thousand separators, use dot for decimal point)
	const unformatNumber = (value) => {
		// Remove all thousand separators and replace decimalSeparator with a dot (.)
		const rawValue = value
			.replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
			.replace(decimalSeparator, '.')

		// Limit the decimal part to the allowed digitLimit
		const parts = rawValue.split('.')
		if (parts[1]) {
			parts[1] = parts[1].slice(0, digitLimit) // Truncate extra decimal digits
		}

		return parts.join('.')
	}

	// Sanitize the input: remove non-numeric characters and ensure only one decimal separator
	const sanitizeInput = (value) => {
		// Replace anything that's not a digit or decimal separator
		let sanitizedValue = value.replace(
			new RegExp(`[^0-9\\${decimalSeparator}]`, 'g'),
			'',
		)

		// Ensure there is only one decimal separator
		const decimalIndex = sanitizedValue.indexOf(decimalSeparator)
		if (decimalIndex !== -1) {
			sanitizedValue =
				sanitizedValue.slice(0, decimalIndex + 1) +
				sanitizedValue
					.slice(decimalIndex + 1)
					.replace(new RegExp(`\\${decimalSeparator}`, 'g'), '')
		}

		return sanitizedValue
	}

	// Handle input as user types
	const onInput = (event) => {
		let value = event.target.value

		// Sanitize the input
		value = sanitizeInput(value)

		// If the value is empty or invalid, reset the inputValue
		if (value === '' || isNaN(value.replace(decimalSeparator, '.'))) {
			inputValue.value = ''
			return
		}

		// If maxValue is provided and is a ref, validate the unformatted number
		const unformatted = parseFloat(unformatNumber(value))
		if (
			maxValue &&
			unformatted > (isRef(maxValue) ? maxValue.value : maxValue)
		) {
			// Set input to maxValue if it exceeds
			const max = isRef(maxValue) ? maxValue.value : maxValue
			inputValue.value = formatNumber(
				max.toString().replace('.', decimalSeparator),
			)
			return
		}

		// Reformat the value with chosen separators
		inputValue.value = formatNumber(value)
	}

	// Computed property for unformatted value
	const unformattedValue = computed(() => {
		if (!inputValue.value) return ''
		return parseFloat(unformatNumber(inputValue.value)) || ''
	})

	// Watch maxValue: if maxValue is given and changes, validate and adjust inputValue if needed
	if (isRef(maxValue)) {
		watch(maxValue, (newMax) => {
			if (unformattedValue.value > newMax) {
				inputValue.value = formatNumber(
					newMax.toString().replace('.', decimalSeparator),
				)
			}
		})
	}

	return {
		inputValue,
		unformattedValue,
		onInput,
	}
}

// **********************************************************************************************************************
const basPlan = ref([
	{ id: 1, account: '1510 Kundfordringar' },
	{ id: 2, account: '1511 Kundfordringar' },
	{ id: 3, account: '1910 Kassa' },
	{ id: 4, account: '1920 PlusGiro' },
	{ id: 5, account: '1930 Bankkonto' },
	{ id: 6, account: '2440 Leverantörsskulder' },
	{ id: 7, account: '2441 Leverantörsskulder' },
	{ id: 8, account: '2610 Utgående moms på försäljning inom Sverige, 25 %' },
	{ id: 9, account: '2613 Utgående moms för uthyrning, 25 %,' },
	{ id: 10, account: '2615 Utgående moms, import av varor, 25 %' },
	{ id: 11, account: '2621 Utgående moms på försäljning inom Sverige, 12 %' },
	{ id: 12, account: '2625 Utgående moms, import av varor, 12 %' },
	{ id: 13, account: '2631 Utgående moms på försäljning inom Sverige, 6 %' },
	{ id: 14, account: '2635 Utgående moms, import av varor, 6 %' },
	{ id: 15, account: '2641 Debiterad ingående moms' },
	{
		id: 16,
		account:
			'2642 Debiterad ingående moms i anslutning till frivillig skattskyldighet',
	},
	{ id: 17, account: '3010 Försäljning inom Sverige, 25 % moms' },
	{ id: 18, account: '3002 Försäljning inom Sverige, 12 % moms' },
	{ id: 19, account: '3003 Försäljning inom Sverige, 6 % moms' },
	{ id: 20, account: '3004 Försäljning inom Sverige, momsfri' },
	{ id: 21, account: '3105 Försäljning varor till land utanför EU' },
	{ id: 22, account: '3106 Försäljning varor till annat EU-land, momspliktig' },
	{ id: 23, account: '3108 Försäljning varor till annat EU-land, momsfri' },
	{ id: 24, account: '3540 Faktureringsavgifter' },
	{ id: 25, account: '3591 Förseningsavgifter, ej moms' },
	{ id: 26, account: '3740 Öres- och kronutjämning' },
])

const formatDateToYYYYMMDD = (date) => {
	const year = date.getFullYear()
	const month = String(date.getMonth() + 1).padStart(2, '0') // getMonth() is 0-indexed
	const day = String(date.getDate()).padStart(2, '0')

	return `${year}-${month}-${day}`
}

function formatNumberWithSeparators(number) {
	// Convert number to fixed two decimal places (as a string)
	let formattedNumber = number.toFixed(2) // Ensures 2 decimal digits (e.g., .00)

	// Split the number into integer and decimal parts
	let parts = formattedNumber.split('.')

	// Add thousand separator (space) to the integer part
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

	// Join the integer part and decimal part with a comma
	return parts.join(',')
}

function formatNumber(
	value,
	thousandSeparator = ' ',
	decimalSeparator = ',',
	digitLimit = 2,
	currency = '', // Optional currency symbol
) {
	// Ensure the value is a number with fixed decimal places
	const fixedValue = parseFloat(value).toFixed(digitLimit)
	const parts = fixedValue.split('.')

	// Format the integer part with the chosen thousand separator
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)

	// Join the integer and decimal parts with the chosen decimal separator
	const formattedValue = parts.join(decimalSeparator)

	// Append the currency symbol, if provided
	return currency ? `${formattedValue} ${currency}` : formattedValue
}
export default function useIncomingTransactionFunctions() {
	return {
		getVariables,
		useFormatNumber,
		formatNumber,
		basPlan,
		formatDateToYYYYMMDD,
		formatNumberWithSeparators,
	}
}
