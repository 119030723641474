<template>
	<div>
		<!-- Display the uploaded image preview -->
		<div v-if="uploadedImageUrl" class="image-preview">
			<img :src="uploadedImageUrl" alt="Uploaded image preview" />
		</div>
		<div>{{ uploadedImageUrl }}</div>
		<div class="container" style="background-color: rgb(219, 225, 235)">
			<div>
				<!-- Custom styled file upload button -->
				<!-- <b-button type="is-danger">Choose file</b-button>
			<input type="file" @change="onFileChange" accept="image/*" /> -->
				<label
					class="custom-file-upload"
					style="width: 210px; max-height: 50px"
				>
					<span>Choose File</span>
					<input type="file" @change="onFileChange" accept="image/*" />
				</label>
			</div>
			<div>
				<b-field>
					<b-tag
						size="is-large"
						style="width: 210px; font-size: 14px; height: 40px"
						>{{ fileName ? `${fileName}` : 'No file chosen' }}</b-tag
					>
				</b-field>
			</div>
			<div>
				<b-button
					type="is-success"
					size="medium"
					style="width: 210px"
					@click="uploadStockItemImage"
					:disabled="!selectedFile"
				>
					Upload Image
				</b-button>
				<b-button
					type="is-danger"
					size="medium"
					style="width: 210px; margin-left: 20px"
					@click="saveImage"
					:disabled="!selectedFile"
				>
					Save the image
				</b-button>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'
import cloudinary from 'cloudinary-core'
import UpdateImageLinkMutation from '@/graphql/mutations/stock/UpdateImageLinkMutation.graphql'
import { useMutation } from '@vue/apollo-composable'
import Store from '@/store'
import stockItemStore from '@/_srcv2/pages/stock-ours/store-stock/useStockItemState.js'

export default {
	name: 'CloudinaryUpload',
	setup() {
		const uploadedImageUrl = ref(null)
		const selectedFile = ref(null)
		const fileName = ref(null) // Variable to hold the chosen file name

		const cloudinaryCore = new cloudinary.Cloudinary({
			cloud_name: 'gtbs-coding', // Replace with your actual Cloudinary cloud name
		})

		onMounted(() => {
			uploadedImageUrl.value = null
		})

		const onFileChange = (event) => {
			selectedFile.value = event.target.files[0]
			fileName.value = selectedFile.value ? selectedFile.value.name : null // Update file name
		}

		const uploadImage = async () => {
			if (!selectedFile.value) return

			const formData = new FormData()
			formData.append('file', selectedFile.value)
			formData.append('upload_preset', 'gtbscrm') // Replace with your unsigned upload preset

			try {
				const response = await fetch(
					`https://api.cloudinary.com/v1_1/${
						cloudinaryCore.config().cloud_name
					}/image/upload`,
					{
						method: 'POST',
						body: formData,
					},
				)

				const { secure_url } = await response.json()
				uploadedImageUrl.value = secure_url
				return secure_url
			} catch (error) {
				alert('Upload error:', error)
			}
		}

		const uploadStockItemImage = () => {
			uploadImage().then((secure_url) => {
				alert(`Image url: ${secure_url}`)
			})
		}
		// * set image link
		const { stockId } = stockItemStore()
		console.log(stockId.value)
		console.log(uploadedImageUrl.value)
		console.log(Store.getters.getUserCurrentCompany)
		const mutationVariable = computed(() => {
			return {
				company_id: Store.getters.getUserCurrentCompany,
				stock_id: stockId.value,
				stock_image_link: uploadedImageUrl.value,
			}
		})
		console.log(mutationVariable.value)
		const {
			mutate: mutateImageLink,
			onDone: onDoneImageLink,
			onError: onErrorImageLink,
		} = useMutation(UpdateImageLinkMutation, () => ({
			variables: mutationVariable.value,
		}))
		onDoneImageLink(() => {
			alert('Image saved successfully!')
		})
		onErrorImageLink((err) => console.log('Error: ', err))

		const saveImage = async () => {
			if (!uploadedImageUrl.value) {
				alert('Please upload an image first.')
				return
			} else {
				mutateImageLink()
			}
		}

		return {
			onFileChange,
			uploadStockItemImage,
			saveImage,
			uploadedImageUrl,
			selectedFile,
			fileName,
		}
	},
}
</script>

<style scoped>
/* Hide the default file input */
input[type='file'] {
	display: none;
}

/* Custom file upload button styling */
.custom-file-upload {
	display: inline-block;
	padding: 10px 20px;
	color: #fff;
	background-color: #007bff;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
	font-weight: bold;
	text-align: center;
	line-height: 20px; /* Center text vertically within the label */
	margin-right: 10px;
	margin-left: 20px;
	width: 210px;
	transition: background-color 0.3s ease;
}

.custom-file-upload:hover {
	background-color: #0056b3;
}

/* Styling for the file name display */
.file-name {
	font-size: 10px;
	margin-top: 10px;
	color: #333;
}

.image-preview {
	margin-top: 20px;
}

.image-preview img {
	max-width: 100%;
	height: auto;
}
.container {
	display: flex;
	gap: 20px; /* Space between the boxes */
	justify-content: left; /* Center items horizontally */
	padding-bottom: 30px;
	padding-top: 30px;
	border-radius: 5px;
}

/* Individual box styling */
.box {
	opacity: 1;
	font-size: 16px;
	text-align: center;
	min-width: 100px;
}
</style>
