var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',[_vm._v("Loading...")]):_vm._e(),(!_vm.loading)?_c('div',[_c('div',{staticStyle:{"background-color":"rgb(76, 92, 122)"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"start","font-weight":"900","padding":"20px"}},[_vm._m(0),_c('div',{staticStyle:{"color":"white"}},[_c('b-radio',{attrs:{"type":"is-warning","name":"name","native-value":"false"},model:{value:(_vm.radioButton),callback:function ($$v) {_vm.radioButton=$$v},expression:"radioButton"}},[_vm._v(" Unsettled ")]),_c('b-radio',{attrs:{"type":"is-warning","name":"name","native-value":"true"},model:{value:(_vm.radioButton),callback:function ($$v) {_vm.radioButton=$$v},expression:"radioButton"}},[_vm._v(" Settled ")]),_c('b-radio',{attrs:{"name":"name","type":"is-warning","native-value":"%"},model:{value:(_vm.radioButton),callback:function ($$v) {_vm.radioButton=$$v},expression:"radioButton"}},[_vm._v(" All ")])],1)]),_c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table striped bordered","theme":"black-rhino","fixed-header":true,"search-options":{
						enabled: true,
					},"sort-options":_vm.sortOptions,"pagination-options":{
						enabled: true,
						mode: 'records',
						perPage: 10,
						position: 'bottom',
						perPageDropdown: [10, 15, 20, 100],
						dropdownAllowAll: false,
						setCurrentPage: 1,
						jumpFirstOrLast: true,
						firstLabel: 'First',
						lastLabel: 'Last',
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page', // for 'pages' mode
						allLabel: 'All',
					}},on:{"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'select')?_c('span',[_c('b-button',{staticClass:"is-small is-success"},[_vm._v("Select")])],1):(props.column.field === 'stockAmount')?_c('span',[_c('p',{staticClass:"text-bold text-align-right text-blue"},[_vm._v(" "+_vm._s(props.row.stockAmount)+" ")])]):(props.column.field === 'outstandingBalance')?_c('span',[_c('p',{staticClass:"text-align-right"},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]):(props.column.field === 'outstandingCost')?_c('span',[_c('p',{staticClass:"text-bold text-green text-align-right"},[_vm._v(" "+_vm._s((props.row.outstandingCost / 100).toFixed(2))+" kr. ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,3636217628)})],1)])]):_vm._e(),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_c('b-button',{staticClass:"is-danger margin-top-30 margin-left-30",on:{"click":_vm.setOutstandingValues}},[_vm._v("Reset Outstanding Values")])],1),_c('download-csv',{attrs:{"data":_vm.rows}},[_c('div',{staticClass:"margin-top-30 width-200",staticStyle:{"margin-right":"15px"}},[_c('b-button',{attrs:{"type":"is-danger","outlined":""}},[_vm._v(" Download the List ")])],1)])],1),(_vm.isModalVisible)?_c('div',[_c('ModalStartupFrame',{attrs:{"onClose":_vm.onCloseStartupFrame}})],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content margin-right-10",staticStyle:{"color":"white"}},[_c('b',[_vm._v("Selection:")])])}]

export { render, staticRenderFns }