<!-- @format -->

<template>
	<layout-wide>
		<sub-nav-bar page="Transaction" />
		<div>
			<div>
				<b-field class="margin-top-20" label="Select payments date">
					<b-datepicker
						v-model="selectedDate"
						:max-date="today"
						:show-week-number="showWeekNumber"
						:locale="locale"
						:first-day-of-week="firstDayOfWeek"
						placeholder="Click to select..."
						icon="calendar-today"
						trap-focus
					>
					</b-datepicker>
				</b-field>
			</div>
			<div>
				<b-button
					class="is-info margin-top-20 margin-right-10"
					type="submit"
					@click="getAll"
					>Get All</b-button
				>
				<b-button
					class="is-danger margin-top-20 margin-right-10"
					type="submit"
					@click="getBank"
					>Get Bank</b-button
				>
				<b-button
					class="is-success margin-top-20 margin-right-10"
					type="submit"
					@click="getCash"
					>Get Cash</b-button
				>
				<b-button
					class="is-warning margin-top-20"
					type="submit"
					@click="getSetoff"
					>Get Set Off</b-button
				>
			</div>

			<div>
				<div>
					<div>
						<div>
							<div class="has-text-left" style="margin-top: 20px">
								<span class="payment-type"> Total Payment Amount: </span>
								{{ formatNumber(collectionTotals / 100, ' ', ',', 2, 'kr.') }}
								<br />
								<span class="payment-type"> Payment type: </span>
								<span class="payment-type-value">
									{{ reportPaymentType }}
								</span>
							</div>
							<div>
								<div class="margin-bottom-30 margin-top-10">
									<vue-good-table
										id="dispatches"
										:columns="columns"
										:rows="rows"
										styleClass="vgt-table striped bordered"
										theme="black-rhino"
										max-height="1100px"
										:fixed-header="true"
										@on-row-dblclick="onRowDoubleClick"
										@on-cell-click="onCellClick"
										:pagination-options="{
											enabled: true,
											mode: 'records',
											perPage: 25,
											position: 'bottom',
											perPageDropdown: [5, 10, 15, 20, 25],
											dropdownAllowAll: false,
											setCurrentPage: 1,
											jumpFirstOrLast: true,
											firstLabel: 'First',
											lastLabel: 'Last',
											nextLabel: 'next',
											prevLabel: 'prev',
											rowsPerPageLabel: 'Rows per page',
											ofLabel: 'of',
											pageLabel: 'page', // for 'pages' mode
											allLabel: 'All',
										}"
									>
										<template slot="table-row" slot-scope="props">
											<span v-if="props.column.field === 'select'">
												<b-button class="is-small is-success">Cancel</b-button>
											</span>
											<span v-if="props.column.field === 'preview'">
												<b-button class="is-small is-info">Preview</b-button>
											</span>
											<span v-else>
												{{ props.formattedRow[props.column.field] }}
											</span>
										</template>
									</vue-good-table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</layout-wide>
</template>

<script>
import SubNavBar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import dateformat from 'dateformat'
import Store from '@/store'
import {
	computed,
	onMounted,
	reactive,
	ref,
	watchEffect,
	watch,
} from '@vue/composition-api'
import { useResult, useSubscription } from '@vue/apollo-composable'
import GetDailyPaymentCollectingSubscription from '@/_srcv2/graphql/accounting/subscriptions/GetDailyPaymentCollectingSubscription.graphql'
import GetCollectionsTotalSubscription from '@/_srcv2/graphql/accounting/subscriptions/GetCollectionsTotalSubscription.graphql'
import useReportNumber from '@/_srcv2/views/_shared/_composables/format-report-numbers/useReportNumber'
import useIncomingTransactionFunctions from '@/_srcv2/pages/transactions-new/scripts/useIncomingTransactionFunctions.js'

import CancelPaymentMutation from '@/_srcv2/views/pages/accounting/components/collect-payment/CancelPaymentMutation.graphql'
import { useMutation } from '@vue/apollo-composable'

// todo preview invoice
export default {
	name: 'GetDataCollectPayment',
	components: {
		SubNavBar,
	},
	setup() {
		const firstDayOfWeek = 1
		const showWeekNumber = ref(false)
		const today = ref(new Date())
		const locale = ref('sv-SE')
		const { formatNumber } = useIncomingTransactionFunctions()
		onMounted(() => {
			console.log('mounted')
			getRows()
		})
		const reportPaymentType = computed(() => {
			switch (queryVariables.payment_type) {
				case '%%%':
					return 'All Transactions'
				case '%collect bank%':
					return 'Bank Transactions'
				case '%collect cash%':
					return 'Cash Transactions'
				case '%set off%':
					return 'Set Off Transactions'
				default:
					return 'All Transactions'
			}
		})
		const getAll = () => {
			queryVariables.payment_type = '%%%'
		}
		const getBank = () => {
			queryVariables.payment_type = '%collect bank%'
		}
		const getCash = () => {
			queryVariables.payment_type = '%collect cash%'
		}
		const getSetoff = () => {
			queryVariables.payment_type = '%set off%'
		}
		const queryVariables = reactive({
			payment_date: new Date(),
			our_company: Store.getters.getUserCurrentCompany,
			payment_type: '%%%',
		})
		// todo ------------------------------------------------------------------------------------------
		const { result } = useSubscription(
			GetCollectionsTotalSubscription,
			() => queryVariables,
		)
		const collectionTotals = useResult(
			result,
			null,
			(data) => data.payments_aggregate.aggregate.sum.payment_credit,
		)
		// ? --------------------------------------------------------------------------------------------
		const { result: resultDailyTCollections } = useSubscription(
			GetDailyPaymentCollectingSubscription,
			() => queryVariables,
		)
		const dailyCollections = useResult(
			resultDailyTCollections,
			null,
			(data) => data.payments,
		)
		// todo ------------------------------------------------------------------------------------------
		const selectedDate = ref(new Date())
		selectedDate.value = new Date()
		watchEffect(() => {
			queryVariables.payment_date = dateformat(selectedDate.value, 'yyyy-mm-dd')
		})
		watch(dailyCollections, (newVal) => {
			console.log('newVal', newVal)
			getRows()
		})
		// ** -------------------------------------------------------------------------------------------
		const columns = [
			{
				field: 'preview',
				label: 'Preview',
				width: '120',
				numeric: true,
			},
			{
				field: 'paymentId',
				label: 'P. ID',
				width: '50',
				centered: true,
			},
			{
				field: 'paymentType',
				label: 'Type',
				width: '30',
				centered: true,
			},
			{
				field: 'invoiceNumber',
				label: 'Invoice Number',
				width: '65',
				centered: true,
			},
			{
				field: 'customer',
				label: 'Customer',
				width: '100',
				centered: true,
			},
			{
				field: 'paymentAmount',
				label: 'Payment A.',
				width: '120',
				numeric: true,
			},
			{
				field: 'select',
				label: 'Select',
				width: '120',
				numeric: true,
			},
		]
		const { floatedCurrencyAddedNumber } = useReportNumber()
		//--------------------------------------------------------------------------------------------
		const getPaymentAbbreviation = (item) => {
			switch (item) {
				case 'collect bank':
					return 'BT'
				case 'collect bank in advance':
					return 'BT'
				case 'collect bank reminder fee':
					return 'BT'
				case 'collect cash':
					return 'CT'
				case 'collect cash in advance':
					return 'CT'
				case 'collect cash reminder fee':
					return 'CT'
				case 'get payback via collect bank':
					return 'BT'
				case 'get payback collect cash':
					return 'BT'
				case 'pay bank':
					return 'BTU'
				case 'pay cash':
					return 'CTU'
				case 'pay bank in advance':
					return 'BTU'
				case 'pay cash in advance':
					return 'CTU'
				case 'set off':
					return 'SO'
				case 'set off credit invoice':
					return 'SO'
				case 'set off advanced payment':
					return 'SO'
				case 'setoff':
					return 'SO'
				default:
					return '??'
			}
		}
		const totalPaymentAmount = ref(0)
		const isLengthOfResultZero = ref(true)
		const rows = ref([])
		const getRows = () => {
			rows.value = dailyCollections.value.map((item) => {
				return {
					paymentId: item.payment_id,
					invoiceNumber: item.invoice_number,
					customerId: item.pyments_customers_rel.customer_id,
					customerTitle: item.pyments_customers_rel.customer_title,
					customer:
						item.pyments_customers_rel.customer_id +
						' - ' +
						item.pyments_customers_rel.customer_title,
					paymentAmount: floatedCurrencyAddedNumber(item.payment_credit),
					paymentType: getPaymentAbbreviation(item.payment_type),
				}
			})
		}
		// const options = reactive({
		// 	fetchPolicy: 'no-cache',
		// })
		// const {
		// 	result: resultDailyPaymentCollectingValues,
		// 	refetch: refetchDailyPaymentCollectingValues,
		// 	loading,
		// 	onResult,
		// } = useQuery(GetDailyPaymentCollectingValues, () => queryVariables, options)
		// const dailyCollections = useResult(
		// 	resultDailyPaymentCollectingValues,
		// 	null,
		// 	(data) => data.payments,
		// )
		// const dailyPaymentCollectingValuesTotals = useResult(
		// 	resultDailyPaymentCollectingValues,
		// 	null,
		// 	(data) => data.payments_aggregate.aggregate.sum,
		// )
		// ** ------------------------------------------------------------------------------------
		// onResult(() => {
		// 	getTableValues()
		// })
		// ** ------------------------------------------------------------------------------------
		// const collapseTable = () => (isProcessing.value = true)
		// ** ------------------------------------------------------------------------------------
		const isGetInvoiceDataOpen = ref(false)
		const closeGetData = () => {
			isGetInvoiceDataOpen.value = false
			isProcessInvoiceOpen.value = true
		}
		const isProcessInvoiceOpen = ref(false)
		const closeProcessInvoice = () => {
			isProcessInvoiceOpen.value = false
		}
		const ourCompany = ref('')
		const invoiceNumber = ref('')
		const variablesMutation = reactive({
			our_company: '',
			invoice_number: '',
			payment_id: '',
		})
		const {
			mutate: mutatePayment,
			onDone: onCancelPaymentDone,
			onError: onCancelPaymentError,
		} = useMutation(CancelPaymentMutation, () => ({
			variables: variablesMutation,
		}))

		const condition = true
		const setVariables = (row) => {
			return new Promise((resolve, reject) => {
				if (condition) {
					variablesMutation.our_company = Store.getters.getUserCurrentCompany
					variablesMutation.invoice_number = row.invoiceNumber
					variablesMutation.payment_id = row.paymentId
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		onCancelPaymentDone(() => {
			alert('Payment has been cancelled')
		})
		onCancelPaymentError(() => {
			alert('Payment could not be cancelled')
		})
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				console.log('**** select is clicked', params.row)
				setVariables(params.row).then(() => {
					mutatePayment()
				})
			} else if (
				params.column.field === 'invoiceNumber' ||
				params.column.field === 'preview'
			) {
				console.log('**** clicked')
				ourCompany.value = Store.getters.getUserCurrentCompany
				invoiceNumber.value = params.row.invoiceNumber
				isGetInvoiceDataOpen.value = true
			}
		}
		const onRowDoubleClick = (params) => {
			console.log('selected params', params.row.invoiceNumber)
		}
		return {
			onCellClick,
			onRowDoubleClick,
			showWeekNumber,
			locale,
			today,
			selectedDate,
			rows,
			columns,
			totalPaymentAmount,
			getAll,
			getBank,
			getCash,
			getSetoff,
			queryVariables,
			reportPaymentType,
			// loading,
			isLengthOfResultZero,
			// isProcessing,
			// collapseTable,
			firstDayOfWeek,
			closeGetData,
			isGetInvoiceDataOpen,
			isProcessInvoiceOpen,
			closeProcessInvoice,
			invoiceNumber,
			ourCompany,
			collectionTotals,
			dailyCollections,
			formatNumber,
		}
	},
}
</script>

<style scoped>
.margin-top-20 {
	margin-top: 20px;
}
.payment-type {
	color: dodgerblue;
	font-weight: bold;
}
.payment-type-value {
	color: red;
	font-weight: bold;
}
.button-default {
	margin-top: 20px;
	margin-right: 20px;
}
</style>
